<template>
  <b-container fluid>
    <b-row>
      <b-col lg="9" sm="12" class="m-auto">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
              <b-overlay :show="loading">
                  <b-row v-for="(item, index) in dataArray.details" :key="index">
                      <b-col lg="10" sm="10">
                          <ValidationProvider name="Schedule" :vid="`date${index}`" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="date"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                      {{$t('globalTrans.date')}} <span class="text-danger">*</span>
                                  </template>
                                    <flat-pickr class="form-control"
                                      v-model="item.date"
                                      :id="`date${index}`"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </flat-pickr>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="2" sm="4" class="mb-3">
                          <div class="text-right">
                              <b-button v-if="(dataArray.details.length - 1) === index" @click="addItem()" title="Add Item" type="button" class="btn btn-success btn-sm mr-1"><i class="fa fa-plus m-0"></i></b-button>
                              <b-button v-if="dataArray.details.length > 1" type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm"><i class="fa fa-trash m-0"></i></b-button>
                          </div>
                      </b-col>
                  </b-row>
                  <hr>
                  <div class="row mt-4">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-1">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button type="button" variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                  </div>
              </b-overlay>
            </b-form>
          </ValidationObserver>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringScheduleStoreApi } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.save') : this.$t('globalTrans.save'),
      loading: false,
      dataArray: {
        team_project_id: 0,
        details: [
          {
            date: ''
          }
        ]
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getScheduleData()
      if (tmp.schedules.length > 0) {
        this.dataArray.details = tmp.schedules
      }
      this.dataArray.team_project_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  computed: {
  },
  methods: {
    getScheduleData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    addItem () {
        const myObj = {
            date: ''
        }
        this.dataArray.details.push(myObj)
    },
    deleteItem (index) {
        this.dataArray.details.splice(index, 1)
    },
    async submit () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.postData(agriResearchServiceBaseUrl, monitoringScheduleStoreApi, this.dataArray)
        // result = await RestApi.putData(agriResearchServiceBaseUrl, `${monitoringTeamUpdate}/${this.id}`, this.role)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
