<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.monitoring_schedule') }} {{ $t('globalTrans.entry') }}</h4>
          </template>
            <template v-slot:headerAction>
            <router-link to="monitoring-schedule" :class="'btn btn-primary text-light mr-2'">{{ $t('research_manage.monitoring_team') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xl="12" lg="12" sm="12">
                    <b-col class="text-center mb-3">
                        <h4>{{ currentLocale == 'bn' ? team.org_name_bn : team.org_name }}</h4>
                        <p>{{ currentLocale == 'bn' ? team.fis_name_bn : team.fis_name }}</p>
                        <h5>{{ currentLocale == 'bn' ? team.team_name_bn : team.team_name }}</h5>
                    </b-col>
                </b-col>
            </b-row>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(schedule)="data">
                          <b-button v-b-modal.modal-4 @click="addSchedule(data.item)" variant=" iq-bg-success mr-1 border" size="sm"><i class="fa fa-plus m-0"></i> {{ $t('globalTrans.add') }}</b-button>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span v-if="data.item.is_scheduled === 1">{{ $t('research_manage.scheduled') }}</span>
                        <span v-else>{{ $t('research_manage.no_schedule_yet') }}</span>
                    </template>
                    <template v-slot:cell(details)="data">
                        <b-button variant=" iq-bg-success border" v-b-modal.modal-5 @click="details(data.item)" :title="$t('research_manage.monitoring_schedule')" size="sm"><i class="fa fa-eye m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="$t('research_manage.monitoring_schedule') + ' ' + $t('globalTrans.entry')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <FormV :id="editItemId" :key="editItemId"/>
        </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('research_manage.monitoring_schedule') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <ScheduleDetail :id="editItemId" :team="team" :key="editItemId"/>
        </p>
    </b-modal>
     <b-modal id="modal-6" size="lg" :title="$t('research_manage.monitoring_schedule') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Details :id="editItemId" :team="team" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import ScheduleDetail from './ScheduleDetail'
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { scheduleProjectListApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        FormV,
        ScheduleDetail
    },
    data () {
        return {
            editItemId: 0,
            team: {}
        }
    },
    created () {
      if (this.$route.query.id) {
          this.loadData()
          this.team = this.getTeamData()
      }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('research_manage.project_id'), class: 'text-left' },
                { label: this.$t('research_manage.project_title'), class: 'text-left' },
                { label: this.$t('research_manage.project_type'), class: 'text-left' },
                { label: this.$t('research_manage.monitoring_schedule'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.details'), class: 'text-center' }
                ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'project.proposal_auto_id' },
                { key: 'project.project_title_bn' },
                { key: 'project_type_bn' },
                { key: 'schedule' },
                { key: 'status' },
                { key: 'details' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'project.proposal_auto_id' },
                { key: 'project.project_title' },
                { key: 'project_type' },
                { key: 'schedule' },
                { key: 'status' },
                { key: 'details' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    methods: {
        details (item) {
            this.editItemId = item.id
        },
        addSchedule (item) {
            this.editItemId = item.id
        },
        searchData () {
            this.loadData()
        },
        getTeamData () {
            const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.query.id))
            return JSON.parse(JSON.stringify(tmpData))
        },
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getData(agriResearchServiceBaseUrl, `${scheduleProjectListApi}/${this.$route.query.id}`)
            if (result.success) {
                this.$store.dispatch('setList', this.getRelationalData(result.data.data))
                this.paginationData(result.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        getRelationalData (data) {
            const listData = data.map(item => {
                const tmpType = this.$store.state.AgriResearch.commonObj.researchTypeList.find(type => type.value === parseInt(item.project.research_type))
                const newData = {
                    project_type: tmpType !== undefined ? tmpType.text_en : '',
                    project_type_bn: tmpType !== undefined ? tmpType.text_bn : ''
                }
                return Object.assign({}, item, newData)
            })
            return listData
        }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
