<template>
  <b-container fluid>
    <b-row>
      <b-col lg="9" sm="12" class="m-auto">
          <b-table-simple striped bordered small hover>
            <thead>
                <b-tr>
                    <b-th colspan="4">
                        <h5 class="text-white text-center contentTitle">{{ $t('external_research.monitoring_schedule') + ' ' + $t('sidebar.information') }}
                        </h5>
                    </b-th>
                </b-tr>
                <tr>
                    <th class="text-center" width="10%">{{ $t('globalTrans.sl_no') }}</th>
                    <th width="30%" scope="col">{{ $t('globalTrans.date') }}</th>
                </tr>
            </thead>
            <b-tbody>
                <b-tr v-for="(item, index) in dataArray.details" :key="index">
                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                    <b-td>{{ item.date | dateFormat }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
// import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  props: ['id'],
  components: {
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.save') : this.$t('globalTrans.save'),
      loading: false,
      dataArray: {
        details: [
          {
            date: ''
          }
        ]
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getScheduleData()
      if (tmp.schedules.length > 0) {
        this.dataArray.details = tmp.schedules
      }
    }
  },
  mounted () {
    core.index()
  },
  computed: {
  },
  methods: {
    getScheduleData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
